import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
    --primary-color: #903BB8;
    --secondary-color: #242424; 
    --nav-height: 50px;
    --shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
              0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
              0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
 
* {
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
}

body {
  //background-color: #964A87;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  overflow-x: hidden;
  max-width: 100vw;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

p {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  color: var(--primary-color);
}


@media screen and (min-width: 960px) {
  :root {
    --nav-height: 80px;
  }
  
  p {
    font-size: 1.125rem;
    max-width: 65ch;
  }
}
`;

export default GlobalStyle;
