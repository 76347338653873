const React = require("react");
const Layout = require("./src/components/Layout").default;
const GlobalStyle = require("./src/components/GlobalStyle").default;

exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      <Layout {...props}>{element}</Layout>
    </>
  );
};
