import React from "react";
import GlobalStyle from "./GlobalStyle";
import Nav from "./Nav";

// import { motion, AnimatePresence } from "framer-motion";

// const duration = 0.2;

// const variants = {
//   initial: {
//     opacity: 0,
//   },
//   enter: {
//     opacity: 1,
//     transition: {
//       duration: duration,
//       delay: duration,
//       when: "beforeChildren",
//     },
//   },
//   exit: {
//     opacity: 0,
//     transition: { duration: duration },
//   },
// };
const Layout = ({ children }) => {
  return (
    <>
      <Nav />

      {/* <AnimatePresence initial={false}>
        <motion.div
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        > */}
      <main>{children}</main>

      {/* </motion.div>
      </AnimatePresence> */}
    </>
  );
};

export default Layout;
