import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import Logo from "../assets/clips_logo_white.svg";
import { RiMenu2Line } from "react-icons/ri";

const overlay = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
  menuOpen: {
    opacity: 1,
    transform: "translate3d(0px,0px,0px)",
  },
  menuClosed: {
    opacity: 0,
    transform: "translate3d(0px,300px,0px)",
  },
};

export const links = [
  { slug: "/", name: "Home", mainMenu: true, footer: false },
  { slug: "/about/", name: "About", mainMenu: true, footer: true },
  { slug: "/services/", name: "Services", mainMenu: true, footer: true },
  { slug: "/stylists/", name: "Stylists", mainMenu: true, footer: true },
  { slug: "/book-online/", name: "Book Online", mainMenu: true, footer: true },
  {
    slug: "/terms-of-service/",
    name: "Terms of Service",
    mainMenu: false,
    footer: true,
  },
];

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <header>
      <StyledNav menuOpen={isOpen}>
        <MenuIcon
          color="white"
          size="1.5em"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />

        <ClipsLogo />
        <Links>
          {links.map((link) => {
            if (link.mainMenu) {
              return (
                <li key={link.slug}>
                  <Link to={link.slug} onClick={handleClose}>
                    {link.name}
                  </Link>
                </li>
              );
            } else {
              return null;
            }
          })}
        </Links>

        <MobileOverlay
          onClick={() => {
            setIsOpen(false);
          }}
          clickable={isOpen}
          variants={overlay}
          animate={isOpen ? "open" : "closed"}
          initial="closed"
          transition={{ duration: 0.15 }}
        >
          <MobileLinks
            clickable={isOpen}
            variants={overlay}
            initial="closed"
            animate={isOpen ? "menuOpen" : "menuClosed"}
            transition={{ delay: 0.3 }}
          >
            {links.map((link) => {
              if (link.mainMenu) {
                return (
                  <li key={link.slug}>
                    <Link to={link.slug} onClick={handleClose}>
                      {link.name}
                    </Link>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </MobileLinks>
        </MobileOverlay>
      </StyledNav>
    </header>
  );
};

export default Nav;

const ClipsLogo = styled(Logo)`
  width: 80px;
  height: auto;
  justify-self: center;
  @media screen and (min-width: 960px) {
    width: 125px;
  }
`;

const StyledNav = styled.nav`
  position: fixed;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  align-items: center;
  padding: 0 1rem;
  top: 0;
  background-color: var(--primary-color);
  width: 100%;
  height: var(--nav-height);
  z-index: 10;
  @media screen and (min-width: 960px) {
    grid-template-columns: auto 1fr;
  }
`;

const MenuIcon = styled(RiMenu2Line)`
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const Links = styled.ul`
  display: none;
  color: white;
  list-style: none;
  font-size: 1.75rem;
  justify-content: flex-end;
  a {
    color: white;
    text-decoration: none;
    font-weight: 300;
    margin: 0 1rem;
  }
  @media screen and (min-width: 960px) {
    display: flex;
  }
`;

const MobileLinks = styled(motion.ul)`
  border: solid 1px rgba(255, 255, 255, 0.95);
  padding: 1em 2em;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 4rem;
  text-align: center;
  list-style: none;
  margin: 0;
  border-radius: 0.25rem;
  box-shadow: var(--shadow);
  pointer-events: ${({ clickable }) => (clickable ? "auto" : "none")};
  background: rgba(255, 255, 255, 0.75);
  @supports (backdrop-filter: blur(1px)) {
    backdrop-filter: blur(8px);
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
`;

const MobileOverlay = styled(motion.div)`
  position: absolute;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(255, 255, 255, 0.75);

  pointer-events: ${({ clickable }) => (clickable ? "auto" : "none")};
  @media screen and (min-width: 960px) {
    display: none;
  }
`;
